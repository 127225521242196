<template>
  <div>
    <transition name="fade">
      <div class="mask" v-show="detailFlag">
        <transition name="fade">
          <div class="detail" :visible="detailFlag">
            <div class="header">{{plateNumber}} - 停车记录
              <i class="el-icon-close" @click="$emit('close')"></i>
            </div>
            <!--列表区域-->
            <div class="tableWrapper" style="padding: 40px 10px 5px;">
              <el-table  v-loading="loading" :data="tableData" style="width: 100%">
                <el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column>
                <el-table-column align='center' :prop="item.prop" :label="item.label" :width="item.width" v-for="item in tableCols" :key="item.prop" :formatter="item.formatter"></el-table-column>

                <!--<el-table-column label="缴费状态" align="center">-->
                  <!--<template slot-scope="scope">-->
                    <!--<span :class="scope.row.parkType == 3 ? 'greenSty' : 'redSty'">{{scope.row.parkType == 3 ? '已缴费' : '欠费'}}</span>-->
                  <!--</template>-->
                <!--</el-table-column>-->
              </el-table>
            </div>
            <!--分页器-->
            <div class="pagerWrapper">
              <div class="block">
                <el-pagination v-if="total != 0" @current-change="handleCurrentChange" :current-page="page" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="total">
                </el-pagination>
              </div>
            </div>
            <div style="text-align: center">
              <el-button type="primary" style="margin-top: 10px;" @click="$emit('close')">关闭</el-button>
            </div>
          </div>




        </transition>



      </div>
    </transition>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        loading: false,
        dialogVisible: false,
        page: 1,
        pageSize: 10,
        total: 0,
        tableCols: [{
          prop: 'parkName',
          label: this.$t("list.park_name"),
          width: ''
        }, {
          prop: 'strEntryTime',
          label: '入场时间',
          width: ''
        }, {
          prop: 'strExitTime',
          label: '出场时间',
          width: ''
        }, {
          prop: 'parkTime',
          label: '停车时长',
          width: ''
        }
        ]
      }
    },
    methods: {
      // 分页处理
      handleCurrentChange (val) {
        this.page = val;
        this.searParkRecordList();
      }
    },
    mounted () {
      if (this.detailFlag) {
        this.searParkRecordList()
      }
    },
    props: ["detailFlag", 'tableData', 'plateNumber']
//    props: {
//    detailFlag: {
//      default: false
//    }
//  }
  }
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
  .detail
    width: 61.4%;
    position: absolute;
    top: 8.6%;
    left: 20.1%;
    background: #FFFFFF;
    box-shadow: 0 0 4px 1px rgba(128,145,165,0.30);
    border-radius: 3px;
    z-index: 1000;
    box-sizing: border-box;
    padding: 20px 0;
    .header
      position: absolute;
      background: #3F4A56;
      border-radius: 3px 3px 0 0;
      color: #fff;
      width: 100%;
      top: 0;
      left: 0;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      padding: 0 12px;
      box-sizing: inherit;
      z-index: 100;
      .el-icon-close
        float: right;
        margin-top: 14px;
  .mask
    background: rgba(49,53,65,0.60) no-repeat center/contain;
    overflow: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
  .greenSty{
    display inline-block;
    background-color greenyellow;
    padding 4px 15px;
  }
  .redSty{
    display inline-block;
    background-color orange;
    padding 4px 20px;
  }
</style>
