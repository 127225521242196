<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <div style="text-align: right;">
        <el-button @click="$router.go(-1)">返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content">
      <el-tabs v-model="activeName2">
        <!--<el-tab-pane label="基本信息" name="first">-->
        <h2 class="title tabSty">
          <div class="title_icon"></div>
          基本信息
        </h2>
        <!--<pic-compontent></pic-compontent>-->
        <el-row>
          <el-col :span="6" v-for="v in dataArr" :key="v.name">
            <el-row>
              <el-col :span="9">
                <div class="name">{{ v.name }}</div>
              </el-col>
              <el-col :span="15">
                <div class="value">
                  {{
                    (v.formatter && v.formatter(formInline[v.prop])) ||
                    formInline[v.prop] ||
                    (v.formatter && v.formatter(parkA[v.prop])) ||
                    parkA[v.prop]
                  }}
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="18">
            <el-row>
              <el-col :span="3">
                <div class="name">地址</div>
              </el-col>
              <el-col :span="21">
                <div class="value">
                  {{ formInline.parkA ? formInline.parkA.parkAddress : "" }}
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <!--</el-tab-pane>-->
      </el-tabs>
      <el-tabs>
        <!--<el-tab-pane label="车辆信息">-->
        <h2 class="title tabSty">
          <div class="title_icon"></div>
          车辆信息
        </h2>
        <div style="height: 280px;">
          <div class="carImg">
            <div class="car_img" v-if="entryPlatePicURL">
              <img
                :src="entryPlatePicURL"
                width="100%;"
                height="100%"
                @click="showBigImg(entryPlatePicURL)"
              />
            </div>
          </div>
          <div class="carInfo">
            <div class="Line">
              <div class="lineSize">概況</div>
              <div class="showLine"></div>
            </div>
            <el-row>
              <el-col :span="12" v-for="v in inFoArr" :key="v.name">
                <el-row>
                  <el-col :span="9">
                    <div class="name">{{ v.name }}</div>
                  </el-col>
                  <el-col :span="15">
                    <div class="value">
                      {{
                        (v.formatter && v.formatter(parkRecordA[v.prop])) ||
                        parkRecordA[v.prop]
                      }}
                    </div>
                  </el-col>
                </el-row>
              </el-col>

              <el-col :span="12">
                <el-row>
                  <el-col :span="9">
                    <div class="name">疑似记录</div>
                  </el-col>
                  <el-col :span="15">
                    <div
                      class="value pointer"
                      @click="showDetail"
                      style="color: #3d94dd;"
                    >
                      查询
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
        <div>
          <div class="Line">
            <div class="lineSize">入场时序图</div>
            <div class="showLine"></div>
          </div>
          <div class="imageLong">
            <div class="car_img" v-if="entryFeatPicURL">
              <img
                :src="entryFeatPicURL"
                width="100%;"
                height="100%"
                @click="showBigImg(entryFeatPicURL)"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="Line">
            <div class="lineSize">出场时序图</div>
            <div class="showLine"></div>
          </div>
          <div class="imageLong">
            <div class="car_img" v-if="exitFeatPicURL">
              <img
                :src="exitFeatPicURL"
                width="100%;"
                height="100%"
                @click="showBigImg(exitFeatPicURL)"
              />
            </div>
          </div>
        </div>
        <!--</el-tab-pane>-->
      </el-tabs>
    </div>

    <carpoliceDetail
      :detailFlag="dialogVisible"
      :plateNumber="plateNum"
      :tableData="tableData"
      @close="dialogVisible = false"
    ></carpoliceDetail>

    <!--<div class="buttonHandle">-->
    <!--<el-button type="primary" icon="el-icon-search" @click="lookParkRecordList()" >取消</el-button>-->
    <!--</div>-->
  </div>
</template>
<script>
// import picCompontent from "./picCompontent";
import carpoliceDetail from "./carpoliceDetail";
import { showBigImage, dateFormat } from "@/common/js/public.js";
//  import history from './history'
export default {
  name: "hello",
  data() {
    const start = new Date();
    const end = new Date(new Date().getTime());
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    return {
      formInline: {},
      parkRecordA: {},
      plateNum: "",
      startTime: dateFormat(start, "yyyy-MM-dd HH:mm:ss"),
      endTime: dateFormat(end, "yyyy-MM-dd HH:mm:ss"),
      parkA: [],
      dialogVisible: false,
      carId: "",
      activeName2: "first",
      query: {},
      tableData: [],
      dataArr: [
        {
          name: "报警编号",
          prop: "alarmCode",
        },
        {
          name: "报警类型",
          prop: "type",
          formatter: (type) => {
            if (type == 0) {
              return "超长报警 ";
            } else {
              return "套牌车报警";
            }
          },
        },
        {
          name: "报警时间",
          prop: "reportTime",
        },
        {
          name: "车场名称",
          prop: "parkName",
        },
        {
          name: "车场类型",
          prop: "parkType",
          formatter: (type) => {
            if (type == 1) {
              return "路侧平行 ";
            } else if (type == 2) {
              return " 路侧垂停车场";
            } else if (type == 3) {
              return "封闭车场";
            } else if (type == 4) {
              return "半封闭车场";
            }
          },
        },
      ],
      inFoArr: [
        {
          name: "车牌号",
          prop: "plateNumber",
        },
        {
          name: "车辆类型",
          prop: "carType",
          formatter: (carType) => {
            if (carType == 0) {
              return "小型车 ";
            } else if (carType == 1) {
              return "中型车";
            } else if (carType == 2) {
              return "大型车";
            }
          },
        },
        {
          name: "泊位号",
          prop: "berthCode",
        },
        {
          name: "入场时间",
          prop: "strEntryTime",
          value: "",
        },
        {
          name: "停车时间",
          prop: "parkTime",
          value: "",
        },
        {
          name: "出场时间",
          prop: "strExitTime",
          value: "",
        },
      ],
      entryPlatePicURL: "",
      exitFeatPicURL: "",
      entryFeatPicURL: "",
    };
  },
  methods: {
    // 放大图片
    showBigImg(url) {
      showBigImage(url);
    },
    getDetail() {
      let url = "/acb/2.0/carAlarm/detail/" + this.query.alarmId;
      this.$axios
        .get(url, {
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.state === 0) {
            this.formInline = res.value;
            this.parkRecordA = this.formInline.parkRecordA;
            this.parkRecordA.plateNumber = this.formInline.plateNumber;
            this.plateNum = this.parkRecordA.plateNumber;
            this.parkA = this.formInline.parkA;
            this.exitFeatPicURL = this.formInline.parkRecordA.exitFullPicURL;
            this.entryFeatPicURL = this.formInline.parkRecordA.entryFullPictURL;
            this.entryPlatePicURL = this.formInline.parkRecordA.entryPlatePicURL;
          }
        });
    },
    searParkRecordList() {
      let url = "/acb/2.0/parkRecord/list";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 10,
            carId: this.query.carId,
            startTime: this.startTime,
            endTime: this.endTime,
            entryOrExit: 1,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.total = res.value.total * 1;
            this.tableData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    showDetail() {
      this.dialogVisible = true;
      this.searParkRecordList();
    },
  },
  components: {
    // picCompontent,
    carpoliceDetail,
    //      history
  },
  created() {
    // this.getDetail()
  },
  mounted() {
    if (this.$route.query.alarmId) {
      this.query = this.$route.query;
    }
    this.getDetail();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="stylus" rel="stylesheet/stylus">
.el-table .success-row {
  background: #f0f9eb !important;
}
.tableWrapper{
  /*width:800px;*/
  line-height: 44px;
  overflow: hidden;
}
.name{
  background:rgba(249,250,252,1);
  color:#475669;
}
.value{
  padding-left:18px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.name,.value{
  border:1px solid #E0E6ED;
  height: 46px;
  line-height: 46px;
  text-align: center;
}
.carImg{
  float left;
  display: inline-block;
  width: 28%;
  height: 270px;
  border: 1px solid #d9d9d9;
}
.carInfo{
  float right;
  display: inline-block;
  width: 70%;
  margin-left: 10px;
}
.pointer{
  cursor pointer
}
.imageLong{
  width: 99.5%;
  height: 260px;
  border: 1px solid #d9d9d9;
}
.buttonHandle{
  text-align: center;
}
.Line{
  position: relative;
}
.lineSize{
  display: inline-block;
  position: relative;
  z-index: 2;
  font-size: 20px;
  color: #CCCCCC;
  background-color: #fff;
  margin: 20px;
  padding: 0 10px;
}
.showLine{
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  bottom: 25px;
  background-color: #e4e7ed;
  /*background-color: rebeccapurple;*/
  z-index: 1;
}
.tabSty{
  height: 42px;
  line-height 42px;
  background-color: #e0e6ed;
  width: 100%;
  margin-bottom 10px;
}
.title_icon{
  margin-top 13px !important;
}
.car_img
  background-size: 100% 100%;
  height 260px;
/*height 200px*/
  /*cursor pointer*/
</style>
